.custom-dot-style {
}
.active-Dot {
  background-color: #e7e7e7;
  border: none;
  color: white;
  padding: 1px 1px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 100px;
  width: 15px;
  height: 10px;
}
.inactive-Dot {
  background-color: #4e4f4e93;
  border: none;
  color: white;
  padding: 1px 1px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 100px;
  width: 10px;
  height: 10px;
}
.carousel-item-padding {
  padding: 20px;
  padding-bottom: 50px;
}
.carousel-container {
  padding: 0px;
}
